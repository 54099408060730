
import { Options, Vue } from 'vue-class-component';
import {
  Encounter,
  EncounterType,
  EncounterTypeName,
  EndPrescriptionValue,
  FormSubmission,
  ImageSeries,
  Journey,
  JourneyTypeName,
  Order,
  OrderLensData,
  Prescription,
  QuantityList, SlitLampCondition
} from '@/models';
import {
  EncounterTypesService,
  JourneyEncounterService,
  JourneyPrescriptionService,
  MeniconOrderService,
  MeniconPatientFormSubmissionService,
  PatientJourneyService,
  ZephyrService
} from '@/services/api';
import { Laterality } from '@/custom/menicon/models';
import { BaseButton, BaseIcon, BaseModal, SmartFormComplete } from '@/lib/components';
import { EncounterData, getEncounterData, getSlitLampConfig } from '@/helpers/encounter.helper';
import {
  countErrors,
  getEncounterValidationRules,
  getEndPrescriptionValidationErrors
} from '@/helpers/validators.helper';
import { getItem, getSchemaWithInheritedConditions } from '@/helpers/smart-form.helper';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { SavingStatus } from '@/lib/constants';
import {
  EncounterSummaryData,
  getEncounterScans,
  getEncounterSummaryData
} from '@/custom/menicon/helpers/form-summary.helper';
import { i18n, switchLocale } from '@/i18n/i18n';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
// @ts-ignore
import { defineAsyncComponent } from 'vue';
import { CompleteOrderData, ILensOrderingAnswer, ISelectedLens } from '@/custom/menicon';
import { lensParamsToLensFitting } from '@/custom/menicon/helpers/lens-order.helper';
import ActionModal from '@/lib/components/Modals/ActionModal.vue';
import { IModalAction } from '@/lib';
import { useSessionStore } from '@/stores/session.store';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    journeyId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    encounterId: {
      type: String,
      required: true
    }
  },
  components: {
    ActionModal,
    BaseModal,
    BaseIcon,
    BaseButton,
    SmartFormComplete
  }
})
export default class EncounterPageLoader extends Vue {
  loading = true;
  journeyId!: string;
  journey: Journey | null = null;
  patientId!: string;
  encounterId!: string;
  encounter: Encounter | null = null;
  formSubmission: FormSubmission | null = null;
  lastPrescriptions: Prescription[] = [];
  encounters: Encounter[] = [];
  orderService = new MeniconOrderService(this.patientId);
  order: Order | null = null;
  journeyService = new PatientJourneyService(this.patientId);
  encounterService = new JourneyEncounterService(this.patientId, this.journeyId);
  prescriptionsService = new JourneyPrescriptionService(this.patientId, this.journeyId);
  zephyrService =
    process.env.NODE_ENV === 'development' && process.env.VUE_APP_MLS_TEST_PATIENT_ID
      ? new ZephyrService(process.env.VUE_APP_MLS_TEST_PATIENT_ID)
      : new ZephyrService(this.patientId);

  encounterTypesService = new EncounterTypesService();
  formSubmissionService = new MeniconPatientFormSubmissionService(this.patientId);
  scans: Record<Laterality, ImageSeries | null> = { right: null, left: null };
  errors: { [key: string]: string[] } = {};
  validationErrors: { [key: string]: string[] } = {};
  encounterTypes: EncounterType[] = [];
  showProcessingModal = false;
  showFixEncounterModal = false;
  createOrderErrorMessage = '';
  showContactSupportModal = false;
  showConfirmationModal = false;
  exitDraft = false;
  request: CancelTokenSource = axios.CancelToken.source();
  status: SavingStatus = SavingStatus.SAVED;
  endPrescriptionValue: EndPrescriptionValue = {
    reasons: [],
    details: ''
  };

  sessionStore = useSessionStore();
  smartForm = useSmartFormStore();
  notificationStore = useNotificationStore();

  get locale(): string {
    return this.encounter?.locale || i18n.global.locale.value;
  }

  get encounterSummaryData(): EncounterSummaryData | null {
    return this.encounter ? getEncounterSummaryData(this.encounter, this.scans, this.order, this.locale) : null;
  }

  get lateralities(): Laterality[] {
    return this.journey?.laterality ? [this.journey.laterality] : [Laterality.right, Laterality.left];
  }

  get formSubmissionErrorCount(): number {
    return this.encounter?.form_submission_id && this.smartForm.errors[this.encounter.form_submission_id]
      ? Object.values(this.smartForm.errors[this.encounter.form_submission_id]).length
      : 0;
  }

  get errorCount() {
    return +this.formSubmissionErrorCount + +countErrors(this.totalErrors);
  }

  get componentType() {
    return defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "component-[request]" */
          `@/custom/menicon/views/encounter/${this.encounter?.type}/EncounterPage${this.encounter?.completed_at ? 'Summary' : ''}.vue`
        )
    );
  }

  get organisationId(): string {
    return this.sessionStore.currentOrganisationId;
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get formId(): string | null {
    const type = this.encounterTypes.find((encounterType) => encounterType.type === this.encounter?.type);
    return type?.form_slug ? type?.available_forms[0]?.id : null;
  }

  get initialEncounter(): EncounterData | null {
    const initialEncounter = this.encounters.find(
      (encounter: Encounter) => encounter.type === EncounterTypeName.INITIAL_MEASUREMENT
    );
    if (!initialEncounter) {
      return null;
    }
    return getEncounterData(initialEncounter);
  }

  get journeyType(): JourneyTypeName | '' {
    return this.journey?.type || '';
  }

  get encounterData(): EncounterData | null {
    return this.encounter ? getEncounterData(this.encounter) : null;
  }

  get slitLampConfig(): Record<SlitLampCondition, boolean> {
    return this.encounter ? getSlitLampConfig(this.encounter.type) : {
      [SlitLampCondition.CORNEAL_STAINING]: true,
      [SlitLampCondition.CORNEAL_INFILTRATES]: true,
      [SlitLampCondition.PAPILLARY_REACTION]: true,
      [SlitLampCondition.CONJUNCTIVAL_HYPERMIA]: true,
      [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: true
    };
  }

  get rules() {
    return this.encounter ? getEncounterValidationRules(this.encounter, this.journey?.laterality) : {};
  }

  get totalErrors() {
    return { ...this.validationErrors, ...this.errors };
  }

  get currentPrescriptions(): { [laterality: string]: Prescription | null } {
    return {
      [Laterality.right]:
        this.lastPrescriptions.find((prescription: Prescription) => prescription.laterality === Laterality.right) ||
        null,
      [Laterality.left]:
        this.lastPrescriptions.find((prescription: Prescription) => prescription.laterality === Laterality.left) || null
    };
  }

  get isDirty(): boolean {
    return (
      this.formSubmission &&
      this.smartForm.dirty[this.formSubmission.id] &&
      this.smartForm.dirty[this.formSubmission.id].length > 0
    );
  }

  get directOrder(): boolean {
    return isFeatureFlagEnabled(FEATURES.DIRECT_ORDER);
  }

  get savingStatus(): SavingStatus {
    if (this.formSaving) {
      return SavingStatus.SAVING;
    }
    if (this.isDirty && this.formSubmissionErrorCount) {
      return SavingStatus.ERROR;
    }
    return this.status;
  }

  get formSaving(): boolean {
    return this.smartForm.promises.length;
  }

  get fixEncounterInstruction(): string {
    return `${this.$t('custom.menicon.order-failed.instruction.your-order-could-not-be-places')} \n
    ${this.createOrderErrorMessage.split('.').join('. \n')}
    ${this.$t('custom.menicon.order-failed.instruction.correct-the-above')}`;
  }

  get contactSupportInstruction(): string {
    return `${this.$t('custom.menicon.order-failed.instruction.your-order-could-not-be-places')}
      ${this.$t('custom.menicon.order-failed.instruction.contact-support')} \n
      ${this.$t('custom.menicon.order-failed.instruction.remain-draft')}`;
  }

  get fixEncounterModalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('custom.menicon.back-to-encounter'),
        onClick: () => {
          this.showFixEncounterModal = false;
          this.back();
        }
      }
    ];
  }

  get contactSupportModalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('platform.patient.back-to-patient'),
        onClick: () => {
          this.showContactSupportModal = false;
          this.cancel();
        }
      }
    ];
  }

  async created() {
    await this.fetchData();
    this.$watch('formSaving', (curr, old) => {
      // if form has been saved and there are errors on the current page, relaunch validation as items may not be there anymore
      if (!curr && old && this.errorCount) {
        this.revalidateForm();
      }
    });
  }

  beforeUnmount() {
    switchLocale(this.sessionStore.currentUser.locale);
    this.clearErrors();
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchData() {
    if (this.step > 0) {
      await this.$router.replace(this.$route.path);
    }
    try {
      this.loading = true;
      await this.fetchJourney(this.request.token);
      await this.fetchEncounterTypes(this.request.token);
      await this.fetchEncounter(this.request.token);
      await this.fetchOrCreateFormSubmission(this.request.token);
      if (!this.encounter?.completed_at && this.encounter?.type !== EncounterTypeName.INITIAL_MEASUREMENT) {
        await this.fetchCompletedEncounters(this.request.token);
        await this.fetchLastPrescriptions(this.request.token);
      } else if (this.encounter?.completed_at) {
        await this.fetchOrder(this.request.token);
        await this.fetchScans(this.request.token);
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.load-data') as string
        });
        this.cancel();
      }
    } finally {
      this.loading = false;
    }
  }

  async fetchScans(cancelToken: CancelToken) {
    if (this.encounter?.r_mls_id || this.encounter?.l_mls_id) {
      const response = await this.zephyrService.getStudies({
        cancelToken
      });
      this.scans = getEncounterScans(response, this.encounter);
    }
  }

  async fetchJourney(cancelToken: CancelToken) {
    this.journey = await this.journeyService.fetch(this.journeyId, {
      cancelToken
    });
  }

  async fetchEncounter(cancelToken: CancelToken) {
    this.encounter = await this.encounterService.fetch(this.encounterId, {
      cancelToken,
      params: {
        include: 'order'
      }
    });
    if (!this.encounter.completed_at && this.encounter.locale) {
      switchLocale(this.encounter.locale);
    }
  }

  async fetchEncounterTypes(cancelToken: CancelToken) {
    this.encounterTypes = (await this.encounterTypesService.index({ cancelToken })).data;
  }

  async fetchOrCreateFormSubmission(cancelToken: CancelToken) {
    if (this.encounter && this.formId) {
      if (!this.encounter.form_submission_id) {
        const formSubmission = (
          await this.formSubmissionService.create({
            form_id: this.formId,
            organisational_unit_id: this.organisationId
          })
        ).data;
        this.encounter = await this.encounterService.update(this.encounter.id, {
          form_submission_id: formSubmission.id
        });
      }
      if (this.encounter.form_submission_id) {
        const formSubmission = await this.formSubmissionService.fetch(this.encounter.form_submission_id, {
          cancelToken
        });
        if (formSubmission.form?.schema) {
          formSubmission.form.schema = getSchemaWithInheritedConditions(formSubmission.form.schema);
        }
        this.formSubmission = formSubmission;
        this.smartForm.answers = {
          ...this.smartForm.answers,
          [formSubmission.id]: formSubmission.answers
        };
      }
    }
  }

  async fetchOrder(cancelToken: CancelToken) {
    if (this.encounter?.completed_at && this.encounter?.order?.id) {
      this.order = await this.orderService.fetch(this.encounter.order.id, {
        cancelToken
      });
    }
  }

  async fetchCompletedEncounters(cancelToken: CancelToken) {
    this.encounters =
      (await this.journeyService.fetch(this.journeyId, { cancelToken })).encounters?.filter(
        (encounter: Encounter) => encounter.completed_at && encounter.order
      ) || [];
  }

  async fetchLastPrescriptions(cancelToken: CancelToken) {
    this.lastPrescriptions = (
      await this.prescriptionsService.index({
        params: {
          sort: '-created_at',
          include: 'orderItem'
        },
        cancelToken
      })
    ).data;
  }

  async next(sectionId: string | null) {
    if (sectionId) {
      await this.validateSection(sectionId);
    }
    if (!this.errorCount) {
      // @ts-ignore
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          step: String(this.step + 2) // Add 2 because the URL param is 1-based
        }
      });
    }
  }

  async validateSection(sectionId: string) {
    if (this.formSubmission?.form?.schema) {
      const response = await this.formSubmissionService.validateSection(this.formSubmission.id, sectionId);

      if (Object.keys(response.data.validation.errors).length === 0) {
        this.smartForm.clearErrors(this.formSubmission.id);
      }

      for (const [itemId, errors] of Object.entries(response.data.validation.errors)) {
        const item = getItem(this.formSubmission?.form?.schema, itemId.split('.')[0]);
        let isItemPresent = false;

        if (item) {
          isItemPresent = this.smartForm.getItemConditionsMet(this.formSubmission.id, item.conditions);
        }

        if (this.smartForm.getError(this.formSubmission.id, itemId)) {
          if (!isItemPresent) {
            this.smartForm.clearError(this.formSubmission.id, itemId);
          }
          continue; // Don't replace existing errors as they are likely to be more specific
        }

        if (isItemPresent) {
          this.smartForm.errors = {
            ...this.smartForm.errors,
            [this.formSubmission.id]: {
              ...this.smartForm.errors[this.formSubmission.id],
              [itemId]: errors[0]
            }
          };
        }
      }
    }
  }

  revalidateForm() {
    const errors = (this.formSubmission && this.smartForm.errors[this.formSubmission.id]) || {};
    Object.keys(errors).forEach((itemId) => {
      if (this.formSubmission?.form?.schema) {
        const item = getItem(this.formSubmission.form.schema, itemId.split('.')[0]);
        let isItemPresent = false;

        if (item) {
          isItemPresent = this.smartForm.getItemConditionsMet(this.formSubmission.id, item.conditions);
        }
        if (!isItemPresent) {
          this.smartForm.clearError(this.formSubmission.id, itemId);
        }
      }
    });
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }
    this.clearErrors();
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step)
      }
    });
  }

  clearErrors() {
    this.validationErrors = {};
    this.errors = {};
    if (this.encounter?.form_submission_id) {
      this.smartForm.clearErrors(this.encounter.form_submission_id);
    }
  }

  async complete(data: CompleteOrderData = {}) {
    await this.next(data?.sectionId || null);
    if (!this.errorCount) {
      this.loading = true;
      try {
        this.showProcessingModal = true;
        if (data.order && Object.keys(data.order.selectedLenses).length) {
          await this.completeOrder(data.order);
        }
      } catch (error) {
        this.loading = false;
        this.showProcessingModal = false;
        this.back();
        switch (error.response?.status) {
        case 422:
          this.showFixEncounterModal = true;
          this.createOrderErrorMessage = error.response?.data?.errors ?
            Object.keys(error.response.data.errors).map((errorKey: string) => error.response.data.errors[errorKey]?.[0]).join('\n\n') :
            '';
          break;
        default:
          this.showContactSupportModal = true;
        }
        return;
      }

      try {
        await this.encounterService.complete(this.encounterId);
        this.showConfirmationModal = true;
      } catch (error) {
        this.back();
      } finally {
        this.showProcessingModal = false;
        this.loading = false;
      }
    }
  }

  async completeOrder(lensOrdering: ILensOrderingAnswer) {
    const lateralities = (Object.keys(lensOrdering.selectedLenses) as Laterality[])
      .filter((laterality) => lensOrdering.selectedLenses[laterality]?.includeInOrder);
    const quantity = {
      [Laterality.right]: {
        numeric: 0,
        unitOfMeasurement: ''
      },
      [Laterality.left]: {
        numeric: 0,
        unitOfMeasurement: ''
      }
    };
    lateralities.forEach((laterality: Laterality) => {
      const selectedQuantity = lensOrdering.quantityList[laterality].find(
        (quantity: QuantityList) => quantity.id === lensOrdering.selectedQuantityValue[laterality]
      );
      if (selectedQuantity) {
        quantity[laterality].numeric = selectedQuantity.numeric;
        quantity[laterality].unitOfMeasurement = selectedQuantity.unitOfMeasurement;
      }
    });
    const data = lateralities.reduce((acc, laterality: Laterality) => {
      const answer = lensOrdering.selectedLenses[laterality] as ISelectedLens;
      acc[laterality] = {
        quantity: {
          numeric: quantity[laterality].numeric,
          unit_of_measurement: quantity[laterality].unitOfMeasurement
        },
        parameters: {
          lensId: answer.id,
          lensName: answer.name,
          lensCode: answer.code.toString(),
          materialCode: answer.materialCode.toString(),
          materialName: answer.materialName,
          lensParameters: lensParamsToLensFitting(lensOrdering.lensParameters[laterality])
        }
      };
      return acc;
    }, {} as { [key: string]: OrderLensData });
    const laterality = lateralities.length === 1 ? lateralities[0] : null;
    const journeyType = lensOrdering.selectedLenses.right?.familyName || lensOrdering.selectedLenses.left?.familyName;
    await this.orderService.createOrder({
      organisational_unit_id: this.sessionStore.currentOrganisationId,
      encounter_id: this.encounterId,
      data: {
        orderComments: lensOrdering.orderComments,
        ...data
      },
      ...(this.encounter?.type === EncounterTypeName.INITIAL_MEASUREMENT
        ? {
          journey_type: journeyType,
          journey_laterality: laterality
        }
        : {})
    });
  }

  validateEndPrescription() {
    this.validationErrors = getEndPrescriptionValidationErrors(this.endPrescriptionValue);
  }

  updateEndPrescriptionValue(value: EndPrescriptionValue) {
    this.endPrescriptionValue = value;
    if (this.errorCount) {
      this.validateEndPrescription();
    }
  }

  async terminate() {
    try {
      this.validateEndPrescription();
      if (!this.errorCount) {
        await this.complete();
        await this.journeyService.update(this.journeyId, {
          ended_at: new Date().toISOString(),
          ended_reasons: this.endPrescriptionValue.reasons.map((code) => ({
            code
          })),
          ended_further_details: this.endPrescriptionValue.details
        });
      }
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.lens-ordering.cancel.error')
      });
    }
  }

  async updateEncounter(changes: Partial<Encounter>) {
    if (this.encounter) {
      // Update encounter only if there are changes
      const shouldUpdate = Object.keys(changes).some(
        (key: string) => changes[key as keyof Encounter] !== this.encounter?.[key as keyof Encounter]
      );
      if (shouldUpdate) {
        // Clear errors of changed keys
        this.errors = Object.keys(this.errors).reduce((acc, key: string) => {
          if (!Object.keys(changes).includes(key)) {
            acc[key] = this.errors[key];
          }
          return acc;
        }, {} as Record<string, string[]>);
        this.encounter = {
          ...this.encounter,
          ...changes
        };
        this.status = SavingStatus.SAVING;
        try {
          await this.encounterService.update(this.encounter.id, changes);
          this.status = SavingStatus.SAVED;
        } catch (e) {
          if (e.response.status === 422) {
            this.errors = {
              ...this.errors,
              ...e.response.data.errors
            };
          }
          this.status = SavingStatus.ERROR;
        }
      }
    }
  }

  cancel() {
    this.$router.push({
      name: 'prescription-details',
      params: { patientId: this.patientId, journeyId: this.journeyId }
    });
  }
}
