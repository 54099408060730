import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "fixed bottom-0 right-0 mx-4 z-50 my-24 py-6 px-8 bg-danger-500 border border-danger-600 rounded-lg shadow-lg text-white"
}
const _hoisted_3 = { class: "whitespace-no-wrap" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "text-sm font-medium text-danger-300" }
const _hoisted_6 = { class: "bg-gray-800 text-sm font-medium p-3 text-white rounded-lg" }
const _hoisted_7 = { class: "relative flex flex-col select-none w-84" }
const _hoisted_8 = { class: "flex flex-col items-center px-10 pb-10 pt-12" }
const _hoisted_9 = { class: "text-xl font-semibold text-center text-gray-900" }
const _hoisted_10 = { class: "text text-center text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal = _resolveComponent("portal")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!

  return (_ctx.journey && _ctx.encounter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), {
          "journey-type": _ctx.journeyType,
          "journey-id": _ctx.journeyId,
          journey: _ctx.journey,
          "direct-order": _ctx.directOrder,
          "end-prescription-value": _ctx.endPrescriptionValue,
          lateralities: _ctx.lateralities,
          laterality: _ctx.journey && _ctx.journey.laterality,
          encounter: _ctx.encounter,
          "encounter-data": _ctx.encounterData,
          "encounter-summary-data": _ctx.encounterSummaryData,
          "slit-lamp-config": _ctx.slitLampConfig,
          order: _ctx.order,
          "initial-encounter": _ctx.initialEncounter,
          "current-prescriptions": _ctx.currentPrescriptions,
          patient: _ctx.patient,
          "encounter-id": _ctx.encounterId,
          "patient-id": _ctx.patientId,
          "form-submission": _ctx.formSubmission,
          loading: _ctx.loading,
          "form-id": _ctx.formId,
          locale: _ctx.locale,
          name: _ctx.$t(`custom.menicon.encounter.type.${_ctx.encounter.type}`),
          readonly: _ctx.encounter && !!_ctx.encounter.completed_at,
          "show-confirmation-modal": _ctx.showConfirmationModal,
          errors: _ctx.totalErrors,
          rules: _ctx.rules,
          status: _ctx.savingStatus,
          onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateEncounter($event))),
          onComplete: _ctx.complete,
          onNext: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next($event))),
          onEnd: _ctx.terminate,
          onUpdateEndPrescriptionValue: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateEndPrescriptionValue($event))),
          onBack: _ctx.back,
          onBackToPrescription: _ctx.cancel,
          onExit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.exitDraft = true)),
          onSetErrors: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validationErrors = $event)),
          onClearErrors: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearErrors()))
        }, {
          default: _withCtx(() => [
            (_ctx.errorCount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('platform.form.error-message', { errorCount: _ctx.errorCount }, _ctx.errorCount)), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('platform.form.error-instructions')), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["journey-type", "journey-id", "journey", "direct-order", "end-prescription-value", "lateralities", "laterality", "encounter", "encounter-data", "encounter-summary-data", "slit-lamp-config", "order", "initial-encounter", "current-prescriptions", "patient", "encounter-id", "patient-id", "form-submission", "loading", "form-id", "locale", "name", "readonly", "show-confirmation-modal", "errors", "rules", "status", "onComplete", "onEnd", "onBack", "onBackToPrescription"])),
        (_ctx.encounter && _ctx.journey && _ctx.journey.laterality)
          ? (_openBlock(), _createBlock(_component_portal, {
              key: 0,
              to: "workflow-footer-middle"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(`custom.menicon.encounter.laterality.${_ctx.journey.laterality}`)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showProcessingModal)
          ? (_openBlock(), _createBlock(_component_BaseModal, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_BaseIcon, {
                      class: "mb-5 text-5xl text-primary-500",
                      name: "loading-spinner-large",
                      height: "46px"
                    }),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('custom.menicon.processing.title')), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('custom.menicon.processing.instruction')), 1)
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showFixEncounterModal)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 2,
              title: _ctx.$t('custom.menicon.order-failed.title'),
              body: _ctx.fixEncounterInstruction,
              actions: _ctx.fixEncounterModalActions,
              icon: "bell",
              "icon-color": "white",
              "icon-bg-color": "alert-400",
              "show-close": false
            }, null, 8, ["title", "body", "actions"]))
          : _createCommentVNode("", true),
        (_ctx.showContactSupportModal)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 3,
              title: _ctx.$t('custom.menicon.order-failed.title'),
              body: _ctx.contactSupportInstruction,
              actions: _ctx.contactSupportModalActions,
              icon: "bell",
              "icon-color": "white",
              "icon-bg-color": "alert-400",
              "show-close": false
            }, null, 8, ["title", "body", "actions"]))
          : _createCommentVNode("", true),
        (_ctx.exitDraft)
          ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
              key: 4,
              heading: _ctx.$t('custom.menicon.prescriptions.exit'),
              body: _ctx.$t('custom.menicon.prescriptions.exit-message'),
              icon: "warning",
              "icon-color": "alert-400",
              stroke: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseButton, {
                  size: "large",
                  block: "",
                  color: "primary",
                  class: "mt-6",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.exit-to-prescriptions-list')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BaseButton, {
                  size: "large",
                  block: "",
                  color: "muted",
                  class: "mt-3",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.exitDraft = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["heading", "body"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}